//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver';
import ImageReview from '@/components/tools/ImageReview';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getCodeStatusColorCSS, getCodeStatusName } from '@/components/_util/util';
export default {
  components: {
    ImageReview,
    AdvancedInfoSetting: () => import('../modal/AdvancedInfoSetting.vue'),
    PublicCodesSetting: () => import('../modal/PublicCodesSetting.vue'),
    CategorySetting: () => import('../modal/CategorySetting.vue'),
    IframeCodeSingle: () => import('../iframe-code-single/Index.vue'),
    ChoosePageModal: () => import('../modal/ChoosePageModal.vue')
  },

  props: {
    rowData: { type: Array, require: true, default: () => [] },
    pagination: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      loading: false,
      codes: [],
      selectedCodesObject: {},
      codeEditorModalVisible: false,
      selectedCode: null,
      choosePageModalVisible: false,
      pageChoosingActionType: '',
      publicCodeSettingModalVisible: false,
      categorySettingModalVisible: false,
      advancedInfoSettingModalVisible: false
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('code', ['codeById']),
    ...mapGetters('page', ['pages']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),
    ...mapGetters('category', ['categoryById']),
    colorCodeType() {
      return {
        voice_code: 'pink',
        spot_code: 'cyan',
        navi_code: 'blue',
        mix_code: 'purple'
      };
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    selectedCodesArray() {
      return Object.values(this.selectedCodesObject);
    },
    isSelectedCode() {
      return this.selectedCodesArray.length > 0;
    },
    hasSpotOrNaviCode() {
      return this.selectedCodesArray.some(item =>
        ['navi_code', 'spot_code'].includes(item.code_type)
      );
    }
  },
  watch: {
    // rowData() {
    //   this.codes = this.rowData;
    // }
    rowData: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.codes = newValue;
      },
      immediate: true
    }
  },
  mounted() {
    this.getProjectsAndPagesByCompany(this.userInfo.company_id);
  },

  methods: {
    ...mapActions('code', ['addCode', 'removeCode', 'updateCode']),
    ...mapMutations('code', ['UPDATE_CODE_POSITION']),
    ...mapActions('project', ['getProjectsAndPagesByCompany']),
    changeCodeSelected({ target }, code) {
      if (target.checked) {
        this.selectedCodesObject = { ...this.selectedCodesObject, [code.id]: code };
      } else {
        const temp = { ...this.selectedCodesObject };
        delete temp[code.id];
        this.selectedCodesObject = { ...temp };
      }
    },
    async dragCodesEnd({ oldIndex, newIndex }) {
      try {
        // This case: this.codes is re-rendered before we update the order
        let orderBy = 0;
        // Return if the drag and drop position is the same
        if (oldIndex === newIndex) {
          return;
        }
        const firstCode = this.codes[newIndex + 1];
        const secondCode = this.codes[newIndex - 1];
        if (newIndex === 0) {
          orderBy = firstCode?.order_by + 0.5;
        } else if (newIndex === this.codes.length - 1) {
          orderBy = secondCode?.order_by - 0.5;
        } else {
          orderBy = (firstCode?.order_by + secondCode?.order_by) / 2;
        }
        // Update the order by
        const data = await this.$s.api.page.editCodeByIdWithRestriction(
          this.pageId,
          this.codes[newIndex]?.id,
          {
            order_by: orderBy
          }
        );
        this.updateCode(data);
        this.UPDATE_CODE_POSITION({ enterIndex: oldIndex, endIndex: newIndex });
        this.$notification.success({ message: this.$t('Successful') });
      } catch (error) {
        this.$notification.error({
          description: error.message
        });
      }
    },
    colorStatus(code) {
      return getCodeStatusColorCSS(code.status);
    },
    status(code) {
      return getCodeStatusName(code);
    },
    handlePageChange(pageCurrent, pageSize) {
      this.$emit('changePaging', { pageCurrent, pageSize });
    },
    goToCodeEditor(code) {
      const path =
        code.code_type === 'voice_code'
          ? `/projects/${this.projectId}/pages/${this.pageId}/codes/voice-code/${code.id}`
          : `/projects/${this.projectId}/pages/${this.pageId}/codes/navi-code/${code.id}`;
      this.$router.push(path);
    },
    async duplicateCode(codeId) {
      new Promise((resolve, reject) => {
        this.$emit('duplicateCode', { codeId, resolve, reject });
      }).finally(() => {
        this.codes = [...this.rowData]; // ToDo: Update codes list from rowData prop
      });
    },
    downloadLink(code) {
      const timestamp = new Date().getTime();
      code &&
        code.preview_qrcode &&
        FileSaver.saveAs(`${code.preview_qrcode}?time=${timestamp}`, `univoice_code${code.id}.bmp`);
    },
    handlePublicCode({ id, status }) {
      const notifyMessage = {
        success: this.$t('This code is public.'),
        fail: this.$t('This code public failed.')
      };
      this.updateCodeById(id, { ispublic: true, status }, notifyMessage);
    },
    handlePrivateCode({ id, status }) {
      const notifyMessage = {
        success: this.$t('This code is private.'),
        fail: this.$t('This code private failed.')
      };
      this.updateCodeById(id, { ispublic: false, status }, notifyMessage);
    },
    async updateCodeById(codeId, input, notifyMessage) {
      try {
        this.loading = true;
        const data = await this.$s.api.page.editCodeById(this.pageId, codeId, input);
        const index = this.codes.findIndex(item => item.id === data.id);
        this.codes[index] = { ...data };
        this.updateCode(data);
        this.$notification.success({ message: notifyMessage.success });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: notifyMessage.fail });
      } finally {
        this.loading = false;
      }
    },
    openCodeEditorModal(code) {
      this.selectedCode = { ...code };
      this.codeEditorModalVisible = true;
    },
    async handlePublicAndOpenIframeReview(code) {
      try {
        this.loading = true;
        this.selectedCode = { ...code };
        const input = { ispublic: true, status: code.status };
        await this.$s.api.page.editCodeById(this.pageId, code.id, input);
        this.$notification.success({ message: this.$t('This code is public.') });
        this.codeEditorModalVisible = true;
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t('This code public failed.') });
      } finally {
        this.loading = false;
      }
    },
    handleDeleteAllCodes() {
      this.deleteCodes('all');
    },
    handleDeleteCodes(codes) {
      this.deleteCodes(codes.map(item => item.id));
    },
    deleteCodes(codeIds) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('deleteCodes', {
          codeIds,
          resolve,
          reject
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    handleCopyCodesOpening(type = 'multiple', code = null) {
      this.codeAction = type;
      this.selectedCode = code;
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'copy';
    },
    handleMoveCodesOpening(type = 'multiple', code = null) {
      this.codeAction = type;
      this.selectedCode = code;
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'move';
    },
    handleCopyCodes({ selectedPageId, codeIds, resolve }) {
      if (this.codeAction !== 'multiple') codeIds = [this.selectedCode.id];
      new Promise((resolve, reject) => {
        this.$emit('copyCodes', {
          selectedPageId,
          codeIds,
          resolve,
          reject
        });
      }).finally(() => {
        resolve(1);
        this.choosePageModalVisible = false;
        this.selectedCodesObject = {};
        this.codeAction = '';
        this.selectedCode = null;
      });
    },
    handleMoveCodes({ selectedPageId, codeIds, resolve }) {
      if (this.codeAction !== 'multiple') codeIds = [this.selectedCode.id];
      new Promise((resolve, reject) => {
        this.$emit('moveCodes', {
          selectedPageId,
          codeIds,
          resolve,
          reject
        });
      }).finally(() => {
        resolve(1);
        this.choosePageModalVisible = false;
        this.selectedCodesObject = {};
        this.codes = [...this.rowData]; // ToDo: update codes list from rowData props
        this.codeAction = '';
        this.selectedCode = null;
      });
    },
    updatePublicSetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodesArray,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.publicCodeSettingModalVisible = false;
          this.selectedCodesObject = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCategorySetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodesArray,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.categorySettingModalVisible = false;
          this.selectedCodesObject = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAdvanceInfoSetting(payload) {
      new Promise((resolve, reject) => {
        this.loading = true;
        this.$emit('updateCodes', {
          selectedCodes: this.selectedCodesArray,
          payload,
          resolve,
          reject
        });
      })
        .then(() => {
          this.advancedInfoSettingModalVisible = false;
          this.selectedCodesObject = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    categoryName(code) {
      if (code.category) {
        const name = code.category.name;
        return name;
      }
      return '-';
    }
  }
};
