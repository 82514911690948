var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-row",
        { staticClass: "my-xs", attrs: { type: "flex", justify: "start" } },
        [
          _c(
            "a-popconfirm",
            {
              staticClass: "mr-xs",
              attrs: {
                title: _vm.$t("Are you sure delete all codes?"),
                "ok-text": "OK",
                "cancel-text": _vm.$t("Cancel")
              },
              on: { confirm: _vm.handleDeleteAllCodes }
            },
            [
              _c(
                "a-button",
                { attrs: { icon: "delete", disabled: _vm.codes.length < 1 } },
                [_vm._v(" " + _vm._s(_vm.$t("Delete all")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-popconfirm",
            {
              staticClass: "mr-xs",
              attrs: {
                title: _vm.$t("Are you sure delete these codes?", {
                  count: _vm.selectedCodesArray.length
                }),
                "ok-text": "OK",
                "cancel-text": _vm.$t("Cancel")
              },
              on: {
                confirm: function() {
                  return _vm.handleDeleteCodes(_vm.selectedCodesArray)
                }
              }
            },
            [
              _c(
                "a-button",
                { attrs: { icon: "delete", disabled: !_vm.isSelectedCode } },
                [_vm._v(_vm._s(_vm.$t("Delete selected")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-xs",
              attrs: { icon: "copy", disabled: !_vm.isSelectedCode },
              on: { click: _vm.handleCopyCodesOpening }
            },
            [_vm._v(_vm._s(_vm.$t("Copy")))]
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-xs",
              attrs: { icon: "drag", disabled: !_vm.isSelectedCode },
              on: { click: _vm.handleMoveCodesOpening }
            },
            [_vm._v(_vm._s(_vm.$t("Move")))]
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-xs",
              attrs: { icon: "edit", disabled: !_vm.isSelectedCode },
              on: {
                click: function($event) {
                  _vm.advancedInfoSettingModalVisible = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Set advanced information")) + " ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-xs",
              attrs: { icon: "gold", disabled: !_vm.isSelectedCode },
              on: {
                click: function($event) {
                  _vm.categorySettingModalVisible = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Category setting")) + " ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-xs",
              attrs: { icon: "eye", disabled: !_vm.isSelectedCode },
              on: {
                click: function($event) {
                  _vm.publicCodeSettingModalVisible = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Switch public/private")) + " ")]
          )
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "grid-view", attrs: { gutter: [16, 16] } },
        [
          _vm.codes.length
            ? _c(
                "draggable",
                {
                  on: { end: _vm.dragCodesEnd },
                  model: {
                    value: _vm.codes,
                    callback: function($$v) {
                      _vm.codes = $$v
                    },
                    expression: "codes"
                  }
                },
                _vm._l(_vm.codes, function(code, index) {
                  return _c(
                    "a-col",
                    { key: index, attrs: { xs: 24, xl: 12, xxl: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: {
                            hoverable: "",
                            bodyStyle: { padding: "16px 16px 24px" }
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "checkbox-code" } },
                            [
                              _c("a-checkbox", {
                                attrs: {
                                  checked: _vm.selectedCodesObject[code.id]
                                    ? true
                                    : false
                                },
                                on: {
                                  change: function(e) {
                                    return _vm.changeCodeSelected(e, code)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          code.code_type !== "voice_code"
                            ? _c(
                                "div",
                                { staticClass: "not-voice-code" },
                                [
                                  _c(
                                    "a-carousel",
                                    { attrs: { arrows: "", dots: false } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-slick-arrow left-arrow",
                                          attrs: { slot: "prevArrow" },
                                          slot: "prevArrow"
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "left-circle" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-slick-arrow right-arrow",
                                          attrs: { slot: "nextArrow" },
                                          slot: "nextArrow"
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "right-circle" }
                                          })
                                        ],
                                        1
                                      ),
                                      !code.preview_points
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "thumbnail",
                                              attrs: { role: "button" },
                                              on: {
                                                click: function() {
                                                  return _vm.goToCodeEditor(
                                                    code
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/gray-background.png"),
                                                  alt: "gray bg"
                                                }
                                              }),
                                              _c("img", {
                                                staticClass: "draft-icon",
                                                attrs: {
                                                  src: require("@/assets/draft-icon.svg"),
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._l(code.preview_points, function(
                                            point,
                                            i
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "thumbnail",
                                                on: {
                                                  click: function() {
                                                    return _vm.goToCodeEditor(
                                                      code
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value: point.url,
                                                      expression: "point.url"
                                                    }
                                                  ],
                                                  attrs: { alt: "point code" }
                                                })
                                              ]
                                            )
                                          })
                                    ],
                                    2
                                  ),
                                  code.preview_qrcode
                                    ? _c(
                                        "div",
                                        { staticClass: "qr-code" },
                                        [
                                          _c("image-review", {
                                            attrs: {
                                              data: code.preview_qrcode,
                                              size: 68
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c("div", { staticClass: "voice-code" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "thumbnail",
                                    attrs: { role: "button" },
                                    on: {
                                      click: function() {
                                        return _vm.goToCodeEditor(code)
                                      }
                                    }
                                  },
                                  [
                                    code.thumbnail && code.thumbnail.url
                                      ? _c("img", {
                                          attrs: {
                                            src: code.thumbnail.url,
                                            alt: code.thumbnail.alt
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: require("@/assets/gray-background.png"),
                                            alt: "gray bg"
                                          }
                                        }),
                                    _c("div", {
                                      class: [
                                        "thumbnail__content mt-sm",
                                        code.preview_qrcode && "has-qrcode",
                                        code.thumbnail &&
                                          code.thumbnail.url &&
                                          "has-thumbnail-img"
                                      ],
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.nl2br(
                                            code.codes[0].text
                                          )
                                        )
                                      }
                                    })
                                  ]
                                ),
                                code.preview_qrcode
                                  ? _c(
                                      "div",
                                      { staticClass: "qr-code" },
                                      [
                                        _c("image-review", {
                                          attrs: {
                                            data: code.preview_qrcode,
                                            size: 68
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "a-tag",
                                    { staticStyle: { "font-size": "15px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatId")(code.id, "CO")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("a-col", { attrs: { span: 18 } }, [
                                _c("div", { staticClass: "code-title" }, [
                                  _vm._v(" " + _vm._s(code.title) + " ")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { xs: 8 } },
                                [
                                  _c(
                                    "a-tag",
                                    {
                                      attrs: {
                                        color: _vm.colorCodeType[code.code_type]
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t(code.code_type)) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { xs: 16 }
                                },
                                [
                                  _c("span", { staticClass: "mr-xs" }, [
                                    _vm._v(_vm._s(_vm.$t("Status")) + ":")
                                  ]),
                                  _c(
                                    "span",
                                    { style: { color: _vm.colorStatus(code) } },
                                    [_vm._v(_vm._s(_vm.$t(_vm.status(code))))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { xs: 24 }
                                },
                                [
                                  _c("span", { staticClass: "mr-xs" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("code.grid.Category")) +
                                        ": "
                                    )
                                  ]),
                                  _c("span", { staticClass: "text-ellipsis" }, [
                                    _vm._v(_vm._s(_vm.categoryName(code)))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c(
                                "a-col",
                                {
                                  staticClass: "flex content-start",
                                  attrs: { xs: 24 }
                                },
                                [
                                  _c("span", { staticClass: "mr-xs" }, [
                                    _vm._v(_vm._s(_vm.$t("Tags")) + ":")
                                  ]),
                                  _vm._l(code.tags, function(tag, index) {
                                    return _c(
                                      "a-tag",
                                      {
                                        key: index,
                                        staticClass: "flex",
                                        style: {
                                          margin: "0 4px 0 0",
                                          maxWidth:
                                            100 / code.tags.length - 15 + "%"
                                        },
                                        attrs: { color: "blue" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-ellipsis" },
                                          [_vm._v(_vm._s(tag))]
                                        )
                                      ]
                                    )
                                  }),
                                  !code.tags || !code.tags.length
                                    ? _c("span", [_vm._v(" - ")])
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c("a-col", { attrs: { xs: 18 } }, [
                                _c("span", { staticClass: "mr-xs" }, [
                                  _vm._v(_vm._s(_vm.$t("Created")) + ":")
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dayjs")(
                                        code.created,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "a-col",
                                { staticClass: "text-right", attrs: { xs: 6 } },
                                [
                                  _c(
                                    "a-tag",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        color: code.ispublic ? "green" : "red"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              code.ispublic
                                                ? "Public"
                                                : "Private"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "actions" },
                            [
                              _c("a-icon", {
                                attrs: { type: "edit" },
                                on: {
                                  click: function() {
                                    return _vm.goToCodeEditor(code)
                                  }
                                }
                              }),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: _vm.$t(
                                      "Would you like to duplicate this code？"
                                    ),
                                    "ok-text": _vm.$t("Yes"),
                                    "cancel-text": _vm.$t("No")
                                  },
                                  on: {
                                    confirm: function() {
                                      return _vm.duplicateCode(code.id)
                                    }
                                  }
                                },
                                [_c("a-icon", { attrs: { type: "copy" } })],
                                1
                              ),
                              _c(
                                "a-dropdown",
                                [
                                  _c("a-icon", { attrs: { type: "ellipsis" } }),
                                  _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay"
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "1",
                                          attrs: {
                                            disabled: !code.preview_qrcode
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.downloadLink(code)
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "download" }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Download")) +
                                              " "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-menu-item",
                                        { key: "2" },
                                        [
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                placement: "left",
                                                title: _vm.$t(
                                                  "Are you sure to delete this code"
                                                ),
                                                "ok-text": _vm.$t("Yes"),
                                                "cancel-text": _vm.$t("No")
                                              },
                                              on: {
                                                confirm: function() {
                                                  return _vm.deleteCodes([
                                                    code.id
                                                  ])
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "delete" }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Delete"))
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("a-menu-item", { key: "3" }, [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.handleCopyCodesOpening(
                                                  "single-code",
                                                  code
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "copy" }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Copy")))]
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("a-menu-item", { key: "4" }, [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.handleMoveCodesOpening(
                                                  "single-code",
                                                  code
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "drag" }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Move")))]
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "5",
                                          attrs: {
                                            disabled:
                                              code.status !== "PUBLISHED"
                                          }
                                        },
                                        [
                                          code.ispublic
                                            ? _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    margin: "0",
                                                    padding: "0",
                                                    color: "inherit"
                                                  },
                                                  attrs: {
                                                    type: "link",
                                                    disabled:
                                                      code.status !==
                                                      "PUBLISHED"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openCodeEditorModal(
                                                        code
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "code" }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "code.grid.EditWebsite"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "a-popconfirm",
                                                {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    "ok-text": "OK",
                                                    disabled:
                                                      code.status !==
                                                      "PUBLISHED",
                                                    "cancel-text": _vm.$t(
                                                      "Cancel"
                                                    )
                                                  },
                                                  on: {
                                                    confirm: function($event) {
                                                      return _vm.handlePublicAndOpenIframeReview(
                                                        code
                                                      )
                                                    },
                                                    cancel: function() {}
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "popconfirm-title",
                                                      attrs: { slot: "title" },
                                                      slot: "title"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "This code is private now, you need to change it to public to embed. Are you sure to change it to public?"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("a-icon", {
                                                        attrs: { type: "code" }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "code.grid.EditWebsite"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      ),
                                      !code.ispublic
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              key: "6",
                                              on: {
                                                click: function() {
                                                  return _vm.handlePublicCode(
                                                    code
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "unlock" }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("Public")) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      code.ispublic
                                        ? _c(
                                            "a-menu-item",
                                            {
                                              key: "7",
                                              on: {
                                                click: function() {
                                                  return _vm.handlePrivateCode(
                                                    code
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "lock" }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("Private")
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c("p", { staticClass: "mt-md text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")
              ])
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px", "text-align": "right" } },
        [
          _c("a-pagination", {
            attrs: {
              current: _vm.pagination.current,
              "show-size-changer": "",
              "page-size-options": ["10", "20", "24", "30", "40", "50"],
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
              "show-total": function(total) {
                return _vm.$t("Total") + " " + total
              }
            },
            on: {
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.handlePageChange,
              showSizeChange: _vm.handlePageChange
            }
          })
        ],
        1
      ),
      _vm.codeEditorModalVisible
        ? _c("iframe-code-single", {
            attrs: {
              visible: _vm.codeEditorModalVisible,
              code: _vm.selectedCode
            },
            on: {
              cancel: function($event) {
                _vm.codeEditorModalVisible = false
              }
            }
          })
        : _vm._e(),
      _vm.publicCodeSettingModalVisible
        ? _c("public-codes-setting", {
            attrs: {
              visible: _vm.publicCodeSettingModalVisible,
              loading: _vm.loading
            },
            on: {
              cancel: function($event) {
                _vm.publicCodeSettingModalVisible = false
              },
              ok: _vm.updatePublicSetting
            }
          })
        : _vm._e(),
      _vm.categorySettingModalVisible
        ? _c("category-setting", {
            attrs: {
              visible: _vm.categorySettingModalVisible,
              loading: _vm.loading
            },
            on: {
              cancel: function($event) {
                _vm.categorySettingModalVisible = false
              },
              ok: _vm.updateCategorySetting
            }
          })
        : _vm._e(),
      _vm.advancedInfoSettingModalVisible
        ? _c("advanced-info-setting", {
            attrs: {
              visible: _vm.advancedInfoSettingModalVisible,
              "has-spot-or-navi-code": _vm.hasSpotOrNaviCode,
              loading: _vm.loading
            },
            on: {
              cancel: function($event) {
                _vm.advancedInfoSettingModalVisible = false
              },
              ok: _vm.updateAdvanceInfoSetting
            }
          })
        : _vm._e(),
      _vm.choosePageModalVisible
        ? _c("choose-page-modal", {
            attrs: {
              "action-type": _vm.pageChoosingActionType,
              visible: _vm.choosePageModalVisible,
              projects: _vm.projectsAndPages
            },
            on: {
              copy: function(payload) {
                return _vm.handleCopyCodes(
                  Object.assign({}, payload, {
                    codeIds: _vm.selectedCodesArray.map(function(item) {
                      return item.id
                    })
                  })
                )
              },
              move: function(payload) {
                return _vm.handleMoveCodes(
                  Object.assign({}, payload, {
                    codeIds: _vm.selectedCodesArray.map(function(item) {
                      return item.id
                    })
                  })
                )
              },
              cancel: function($event) {
                _vm.choosePageModalVisible = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }